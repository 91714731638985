export default [
    {
    path: '/myIndex',
    name: '个人中心',
    meta: {
        needLogin: true,
		showFooter: true
    },
    component: () => import('../../views/myView/index.vue')
},
];
