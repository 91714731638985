import axios from 'axios';
import {
    BASE_URL
} from './config.js';

const http = axios.create({
    baseURL: `${BASE_URL}/`,
    withCredentials: true,
    timeout: 30000
});

export default http;
