

import http from './axios';
import util from './index';
import {
		userStore
	} from "@/store/userStore.js";

// const http = axios.create({
//     baseURL: `${BASE_URL}/api/`,
//     withCredentials: true,
//     timeout: 30000
// });

let loading = null;
let requestCount = 0;

let notShowError = false;

let responseMsg = '操作失败';

// 请求拦截
http.interceptors.request.use(
    config => {
		
        // 添加token
        // const token = store.state.user.token;
        const token = localStorage.getItem('token');
		console.log('token00000000000000',token)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // config.headers['Content-Type'] = 'application/json;charset=utf-8';
        if (config.headers.showLoading) {
            // loading = Loading.service({
            //     lock: true
            // });
        }
        if (config.notShowError) {
            notShowError = true;
        }
        if (config.responseMsg) {
            responseMsg = config.headers.responseMsg;
        }
        delete config.showLoading;
        delete config.responseMsg;
        delete config.notShowError;
        requestCount += 1;
		// console.log('config==>',config);
        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

// 响应拦截
http.interceptors.response.use(
    response => {
		console.log('response==>',response);
        requestCount -= 1;
        if (loading && requestCount <= 0) {
            loading.close();
        }
        const res = response.data;
        const flag = res.flag || (res.hasOwnProperty('code') && res.code === 200)||res.code === 411;
        if (!flag && !notShowError) {
			if( res.code == 401)
			{
			 // util.showError('请先登录');
				localStorage.removeItem('token');
				const storeInfo = userStore();
				storeInfo.UpdateNoticeLogin()
				return;
				 return Promise.resolve(res);
			}
			else{
				if(res.msg!='用户不存在')
				{
					console.log("返回错误 ",res.msg)
					 util.showError(res.msg || responseMsg);
				}
			}
			
            return Promise.reject(new Error(res.msg || '返回code不为200'));
        } else {
            return Promise.resolve(res);
        }
    },
    error => {
		console.log('error==>',error);
		// alert("我报错了"+JSON.stringify(error))
        requestCount -= 1;
        if (loading && requestCount <= 0) {
            loading.close();
        }
        let msg = error.message;
        if (msg && msg.search('Network Error') >= 0) {
            msg = '网络错误';
        }
        if (msg && msg.search('timeout') >= 0) {
            msg = '请求超时';
        } else {
            if (error.response && error.response.data && error.response.data.Message) {
                msg = error.response.data.Message;
            }
            if (error.response && error.response.status === 401) {
              msg = '请先登录';
			  const storeInfo = userStore();
			  storeInfo.UpdateNoticeLogin()
			  localStorage.removeItem('token');
			  return;
            }
        }
        util.showError(msg);
		console.log("错误 ",msg)
        return Promise.reject(error);
    }
);

export default http;
