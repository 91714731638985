import {
	BASE_URL
} from './config.js';
import {
	ElMessage
} from 'element-plus';
import 'element-plus/es/components/message/style/css'

export default {
	/**
	 * 数字前填0
	 * @param {*} value
	 * @returns
	 */
	toDoubleString(value) {
		return `${value}`.padStart(2, '0');
	},

	/**
	 * 验证手机号
	 * @param {*} phone
	 */
	validatePhone(phone) {
		if (!phone) {
			return false;
		}
		return /^1((3[0-9])|(4[1579])|(5[0-9])|(6[6])|(7[0-9])|(8[0-9])|(9[0-9]))\d{8}$/.test(phone);
	},
/**
	 * 验证邮箱
	 * @param {*} email
	 */
	validateEmail(email) {
		if (!email) {
			return false;
		}
		return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
	},
	/**
	 * 秒转换为时分秒
	 * @param {*} value
	 */
	secondsToTime(value) {
		value = Number(value);
		var hour = Math.floor(value / 3600);
		var minute = Math.floor((value - hour * 3600) / 60);
		var second = Math.floor(value - hour * 3600 - minute * 60);
		return `${toDoubleString(hour)}:${toDoubleString(minute)}:${toDoubleString(second)}`;
	},

	/**
	 * 秒转为天时分秒
	 *
	 * @param {*} value
	 */
	secondsToDay(value) {
		value = Number(value);
		var day = Math.floor(value / (3600 * 24));
		var rightStr = secondsToTime(value - 3600 * 24 * day);
		return day > 0 ? `${day}天${rightStr}` : rightStr;
	},

	/**
	 * 价格展示
	 * @param {*} value
	 * @returns
	 */
	price(value) {
		if (typeof value === 'number' && (value || value === 0)) {
			return value.toFixed(2);
		}
		return value + '';
	},

	/**
	 * 图片前缀
	 * @param {*} value
	 * @returns
	 */
	hostPrefix(value) {
		if (value) {
			if (value && (value.includes('/assets/images/') || value.includes('http') || value.startsWith(
					'data:image/'))) {
				return value;
			}
			if (value.startsWith('/')) {
				return BASE_URL.endsWith('/') ? `${BASE_URL}${value.substr(1)}` : `${BASE_URL}${value}`;
			} else {
				return BASE_URL.endsWith('/') ? `${BASE_URL}${value}` : `${BASE_URL}/${value}`;
			}
		}
		return '';
	},
	browserRedirect() {
		var IsPc = true;
		var sUserAgent = navigator.userAgent.toLowerCase();
		var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
		var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
		var bIsMidp = sUserAgent.match(/midp/i) == "midp";
		var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
		var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
		var bIsAndroid = sUserAgent.match(/android/i) == "android";
		var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
		var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
		IsPc = !(bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM);
		return IsPc;
	},
	/**
	 * 格式化时间
	 * @param {*} time
	 * @param {*} cFormat
	 * @returns
	 */
	dateTime(time, cFormat) {
		var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
		var date = new Date(time);
		var formatObj = {
			y: date.getFullYear(),
			m: date.getMonth() + 1,
			d: date.getDate(),
			h: date.getHours(),
			i: date.getMinutes(),
			s: date.getSeconds(),
			a: date.getDay()
		}
		return format.replace(/{([ymdhisa])+}/g, (result, key) => {
			var value = formatObj[key];
			// Note: getDay() returns 0 on Sunday
			if (key === 'a') {
				return ['日', '一', '二', '三', '四', '五', '六'][value];
			}
			return value.toString().padStart(2, '0');
		});
	},
	showMsg(msg, type = null, duration = 0) {
		ElMessage({
			showClose: false,
			message: msg
		});
	},
	showOk(msg, type = null, duration = 0) {
		ElMessage({
			showClose: false,
			message: msg,
			type: 'success',
		});
	},
	showWarning(msg, type = null, duration = 0) {
		ElMessage({
			showClose: false,
			message: msg,
			type: 'warning',
		});
	},
	showError(msg, type = null, duration = 0) {
		console.log(msg)
		
		ElMessage({
			showClose: false,
			message: msg,
			type: 'error',
		});
	},
	dealHtml(beforeHtml){
		console.log("准备处理富文本")
		var  afterHtml="";
		if (beforeHtml.indexOf('<img') != -1) {
		        afterHtml = beforeHtml.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (img, src) {
		            console.log('img =>', img) // <img src="blob:http://xxxxxxxxx:8888/be990a356" alt="AAA.png" data-href="" style=""/>
		            console.log('src =>', src) // blob:http://xxxxxxxxx:8888/be990a356
		            
		            // 提取 alt
		            // const reg = /alt\s*=\s*([^\s]+)/i
		            // const reg = /alt=(["']+)([\s\S]*?)(\1)/i
		            const reg = /alt\s*=\s*[\'\"]?(.*?)[\'\"][^>]/si
		            const alt = reg.exec(img)
		            console.log('alt =>', alt)
		            // [
		            //   'alt=\"AAA.png\" ',
		            //   'AAA.png'
		            // ]
		 
		            // 提取 style
		            const styleReg = /style\s*=\s*[\'\"]?(.*?)[\'\"][^>]/si
		            const style = styleReg.exec(img)
		            console.log('style =>', style)
					
					src=src.indexOf('http')>=0?src:BASE_URL+src
		            console.log('\n')
		            return '<img src=\"' + src+'\" style=\"' + (style&&style.length>1?style[1]:'') + '\" />' // <img src=v1/downLoadFile?filePath="AAA.png"/>
		        })
		    }
			else{
				afterHtml=beforeHtml
			}
		
		return afterHtml;
	},
	// 添加前缀
	addHost(url)
	{
		var urlStr='';
		if(url&&url.indexOf('http')==-1)
		{
			urlStr=BASE_URL+url
			
		}
		else{
			urlStr=url;
		}
		return urlStr;
	},
	/**
	 * 格式化时间
	 * @param {*} time
	 * @param {*} cFormat
	 * @returns
	 */
	dateTimeStr(time, cFormat) {
		var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
		var date =time? time:new Date();
		var formatObj = {
			y: date.getFullYear(),
			m: date.getMonth() + 1,
			d: date.getDate(),
			h: date.getHours(),
			i: date.getMinutes(),
			s: date.getSeconds(),
			a: date.getDay()
		}
		return format.replace(/{([ymdhisa])+}/g, (result, key) => {
			var value = formatObj[key];
			// Note: getDay() returns 0 on Sunday
			if (key === 'a') {
				return ['日', '一', '二', '三', '四', '五', '六'][value];
			}
			return value.toString().padStart(2, '0');
		});
	},
	
}