import {defineStore} from "pinia";
import http from '@/utils/http';
export const userStore = defineStore('main', {
	// 定义字段
	state:()=>{
		return{
			noticeLogin:0,// 通知登录弹窗
		}
	},
	// 初始化
	getters:{
		
	},
	// 方法
	actions:{
		UpdateNoticeLogin:function(){
			this.noticeLogin++;
		},
		
	},
	// 设置为
})


