import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './element-variables.scss' // 引入自定义主题文件
import {createPinia} from 'pinia'

import Vant from 'vant';
import 'vant/lib/index.css';

// 全局样式
import './styles/index.scss';
import './styles/animate.css';

import api from '@/api/api.js'
import util from '@/utils'



// createApp(App).use(store).use(ElementPlus).use(Vant).use(router).mount('#app')

const app = createApp(App);

app.config.globalProperties.$api = api;
app.config.globalProperties.$util = util;
app.use(createPinia())


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store);
app.use(ElementPlus);
app.use(Vant);
app.use(router);
app.mount("#app");

router.afterEach((to, from, next) => {
	const body = document.querySelector('body');
	body.scrollTo(0, 0);
	window.scrollTo(0, 0);
});
