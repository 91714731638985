import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'

const otherRoute = [];

const routeContext = require.context('./modules', true, /\.js$/);

routeContext.keys().forEach(item => {
	const routeModule = routeContext(item);
	if (Object.keys(routeModule).length > 0) {
		otherRoute.push(...(routeModule.default || routeModule));
	}
});

const routes = [{
		path: '/',
		name: 'layout',
		component: () => import('../components/main.vue'),
		children: [{
				path: '',
				redirect: '/home'
			}, {
				path: '/home',
				name: '首页',
				meta: {
					showFooter: true
				},
				component: () => import('../views/home.vue')
			}, {
				path: '/aboutUs',
				name: '关于我们',
				meta: {
					showFooter: true
				},
				component: () => import('../views/aboutUs.vue')
			}, {
				path: '/product',
				name: '产品和服务',
				meta: {
					showFooter: true
				},
				component: () => import('../views/product.vue')
			}, {
				path: '/news',
				name: '新闻资讯',
				meta: {
					showFooter: true
				},
				component: () => import('../views/news.vue')
			}, {
				path: '/contact',
				name: '联系我们',
				meta: {
					showFooter: true
				},
				component: () => import('../views/contact.vue')
			}, {
				path: '/newInfo',
				name: '详情',
				meta: {
					showFooter: true
				},
				component: () => import('../views/newInfo.vue')
			},
			{
				path: '/chinaDetail',
				name: '详情',
				meta: {
					showFooter: false
				},
				component: () => import('../views/chinaDetail.vue')
			},
			
			// modules 其他路由
			...otherRoute
		]
	},
	// 错误路由
	{
		path: '/404',
		name: 'error',
		meta: {
			keepAlive: true
		},
		component: () => import('../views/error.vue')
	}, {
		path: '/:pathMatch(.*)',
		redirect: '/404'
	}
]

console.log("路由信息",process.env.BASE_URL)
const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
