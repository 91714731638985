import http from '@/utils/http';

export default
{
	/**
	 * 通用接口请求
	 *
	 * @param {*} data
	 * @returns
	 */
	 HtppRequest(url, method, data, success, fail, showLoading = false) {
		 if(method.toLowerCase()=='get')
		 {
			 return http({
			 	url: url,
			 	params: data,
			 	method: method,
			 	headers: {
			 		showLoading: showLoading
			 	}
			 }).then(res => {
			 	success(res);
			 }).catch(err => {
			 	fail(err);
			 });
		 }
		else{
			return http({
				url: url,
				data: data,
				method: method,
				headers: {
					showLoading: showLoading
				}
			}).then(res => {
				success(res);
			}).catch(err => {
				fail(err);
			});
		}
	},
	/**
	 * 通用接口请求
	 *
	 * @param {*} data
	 * @returns
	 */
	 HtppDataRequest(url, method, data, success, fail, showLoading = false) {
		return http({
			url: url,
			data: data,
			method: method,
			headers: {
				showLoading: showLoading
			}
		}).then(res => {
			success(res);
		}).catch(err => {
			fail(err);
		});
	},
	/**
	 * 上传图片
	 *
	 * @param {*} file
	 */
	 uploadFilePicture(file, success,fail)  {
		 console.log("准备上传图片")
		var formData = new FormData();
		formData.append('file', file);
		return http({
			url: 'api/Common/FileUpload',
			method: 'POST',
			headers: {
				'Content-Type': 'multipart/form-data',
			},
			data: formData
		}).then(res => {
			console.log(res);
			var obj = {
				url: res.data.url,
				id: res.data.pictureId
			};
			success(obj);
		}).catch(err => {
			console.error('uploadPicture -> err', err);
			fail(err);
		});
	}
	
}



